<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field grouped>
          <b-field expanded label="Search">
            <!--suppress HtmlUnknownAttribute -->
            <b-input
                v-debounce:300ms="getFilteredPackages"
                placeholder="Search"
                :loading="loadingFilter"
                v-model="searchHold"
            ></b-input>
          </b-field>
          <b-field expanded label="Phase">
            <b-select v-model="phase_id" :loading="loadingPhases" expanded placeholder="Phase">
              <option value="All">All</option>
              <option v-for="phase in phases" :key="phase.id" :value="phase.id">{{ phase.name }}</option>
            </b-select>
          </b-field>
          <b-field v-if="phase_id!=='All'" expanded label="Without Phases">
            <b-checkbox v-model="without_phases" :true-value="1" :false-value="0"></b-checkbox>
          </b-field>
          <b-field label="Create" v-if="canCreate">
            <b-button
                @click="startCreatingPackage()"
                type="is-primary"
                :icon-right="$tc('icons.create')"
            >Create new Package
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <b-table
        class="margin-top is-clickable"
        :data="packages"
        :loading="loadingData"
        :striped="true"
        :hoverable="true"
        :bordered="false"
        scrollable
        @click="viewPackage"
    >
      <b-table-column
          v-slot="props"
          label="ID"
          field="id"
          sortable
          width="40"
          numeric
      >{{ props.row.id }}
      </b-table-column>

      <b-table-column
          v-slot="props"
          label="Name"
          field="name"
          sortable

      >{{ props.row.name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Phases"

      >{{ props.row.phases.length > 0 ? displayPhases(props.row.phases) : 'None' }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Description"


      >
        <div class="ck-content" v-html="clipDescription(props.row.description)"></div>
      </b-table-column>

      <b-table-column
          v-slot="props"
          label="Is Open"
          field="is_open"
          sortable
          numeric
      >{{ props.row.is_open ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Services"

          numeric
      >
        <p class=" icon has-background-grey has-text-white box px-2 py-2">
          {{ props.row.service_ids ? props.row.service_ids.length : 0 }}</p>
      </b-table-column>

      <b-table-column
          v-slot="props"
          label=""
          custom-key="actions"
          centered

      >
      <div v-on:click.stop>
        <b-dropdown position="is-bottom-left" append-to-body aria-role="list" >
          <template #trigger>
            <b-icon
                :icon="$tc('icons.more')"/>
          </template>
          <b-dropdown-item @click="startDelete(props.row)"
                          v-if="canDelete"
                          aria-role="listitem">Delete
          </b-dropdown-item>
        </b-dropdown>
      </div>
      </b-table-column>
    </b-table>
    <b-pagination
        class="mt-4"
        :total="meta.total"
        :current="page"
        :range-before="2"
        :range-after="2"
        :per-page="limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="setPage"
    ></b-pagination>
  </div>
</template>

<script>
import PackageForm from "@/components/services/PackageForm";
import clip from "text-clipper";
import Package from "@/models/Package";
import Phase from "@/models/Phase";
import {mapState} from "vuex";

export default {
  name: "Package.index",
  data() {
    return {
      search: null,
      loadingFilter: false,
      packagesDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      phase_id: 'All',
      loadingData: false,
      loadingPhases: false,
      without_phases: 1,
      searchHold: null,
    };
  },
  props: {
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },

  },
  methods: {
    displayPhases(phases) {

      let names = ''
      phases.map((phase, index) => {
        if (index === 0) {
          names = names + phase.name
        } else {
          names = names + ', ' + phase.name
        }
      })
      return names
    },
    clipDescription(text) {
      return clip(text, 300, {
        html: true,
      });
    },
    startDelete(item) {
      this.$buefy.dialog.confirm({
        title: `Deleting package`,
        confirmText: `Delete package`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this package?`,
        onConfirm: () =>
            Package.Delete(item.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Package deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreatingPackage() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          campus_id: this.$store.state.campus.selected_campus_id,
        },
        component: PackageForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    viewPackage(item) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          proppedPackage: item,
          campus_id: this.$store.state.campus.selected_campus_id,
        },
        component: PackageForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    getFilteredPackages(text) {
      this.search = text;
    },
    setPage(pageNumber) {
      this.loadingData = true;
      this.packagesDataHold = this.packages;
      Package.deleteAll();
      this.page = pageNumber;
      Package.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters,
          ["services", "phases"]
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.packagesDataHold = null;
          }
      );
    },
  },
  watch: {
    filters() {
      this.setPage(this.page);
    },
    selected_campus_id(newValue) {
      this.loadingPhases = true
      Phase.FetchAll({page: 1, limit: 999}, {campus_id: newValue}).finally(() => {
        this.loadingPhases = false
      })
    },
  },
  computed: {
    ...mapState('campus', ['selected_campus_id']),
    phases() {
      return Phase.query().where('campus_id', this.$store.state.campus.selected_campus_id).where('year', this.$store.state.dates.year).get()
    },
    filters() {
      return {
        ...(this.search
            ? {
              search: this.search,
            }
            : {}),
        without_phases: this.without_phases
        , ...(this.phase_id !== 'All'
            ? {
              phase_id: this.phase_id,
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}), year: this.$store.state.dates.year
      };
    },
    packages() {
      if (this.packagesDataHold === null) {
        return Package.query().with('services').with('phases').orderBy('name').get();
      } else {
        return this.packagesDataHold;
      }
    },
    edit_services() {
      return this.$store.getters["entities/user-permissions/find"](
          "edit services"
      );
    },
  },
  mounted() {
    this.$store.state.documentation.tab_name = 'packages'
    this.$store.state.documentation.active_doc_document_path = './Campus Administration/Manage Packages.md'
    this.setPage(this.page, true);


  },
};
</script>
