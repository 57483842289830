<template>
  <div class="columns">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AcademicsWrapper',
  mounted() {
    this.$store.state.documentation.tab_name = 'manage-academics'
    this.$store.state.documentation.active_doc_document_path = './Campus Administration/Manage Academics.md'
  }
}
</script>
