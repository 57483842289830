<template>
  <b-field>
    <b-input
        v-if="type==='filter'"
        v-debounce:300ms="getFiltered"
        placeholder="Service"
        :loading="loading"
        :icon-right="$tc('icons.close-circle')"
        :icon-right-clickable="true"
        v-model="searchTerm"
        v-on:icon-right-click="clear()"
    ></b-input>
    <b-autocomplete
        :dropdown-position="dropdownDirection"
        ref="autocomplete"
        v-if="type==='autocomplete'"
        v-debounce:300ms="getFiltered"
        :data="searchResponse"
        autocomplete="off"
        :loading="loading"
        v-model="searchTerm"
        placeholder="Service"
        :clear-on-select="clearOnSelect"
        :custom-formatter="thing=> name(thing)"
        @select="option => select(option)"
        :clearable="true"
    >
      <template slot-scope="props">
        <p>{{ props.option.id }} | {{ props.option.name }}</p>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import Service from "@/models/Service";

export default {
  name: "ServicesFilter",
  data: function () {
    return {
      loading: false,
      searchTerm: '',
      searchResponse: null,
      activeServiceId: null
    };
  },
  props: {
    persistSearch: {
      type: Boolean,
      default() {
        return true
      }
    }, clearOnSelect: {
      type: Boolean,
      default() {
        return false
      }
    },
    dropdownDirection: {
      type: String,
      default() {
        return 'auto'
      }
    },

    selected_service_id: {
      type: Number, required: false
    },
    type: {
      type: String, default() {
        return 'filter'
      }
    },
    campus_id: {
      type: Number, default() {
        return null
      }
    },  year: {
      type: Number, default() {
        return null
      }
    },

  },
  watch: {
    selected_service_id(newValue) {
      if (newValue !== null) {
        if (this.activeServiceId !== this.selected_service_id) {
          Service.FetchById({id:this.selected_service_id}).then(({entities}) => {
            this.searchResponse = entities.services
            this.$refs.autocomplete.setSelected(entities.services[0])

          })
        }
      }
    }
  },
  mounted() {
    if (this.selected_service_id) {
      if (this.activeServiceId !== this.selected_service_id) {
        Service.FetchById({id:this.selected_service_id}).then(({entities}) => {
          this.searchResponse = entities.services
          this.$refs.autocomplete.setSelected(entities.services[0])

        })
      }
    }
  },
  methods: {
    name(thing) {
      return `${thing.id} | ${thing.name}`
    },
    select(option) {
      if (option === null) {
        this.clear()
      } else {
        this.activeServiceId = option.id
        this.$emit('selected', option)
      }
    },

    clear() {
      this.searchTerm = ''
      this.searchResponse = [];
      this.$emit('cleared')
    },
    getFiltered(text) {
      if (text.length > 0) {
        this.loading = true;

        Service.FetchAll({page: 1, limit: 100}, {
          ...{search: text},
          ...(this.campus_id ? {
            campus_id: this.campus_id
          } : {}),  ...(this.year ? {
            year: this.year
          } : {}),
        }, [], this.persistSearch).then((result) => {
          this.loading = false;

          if (result.entities) {
            this.searchResponse = result.entities.services;
            if (this.type === 'filter') {
              this.$emit('filtered', {result: result, term: this.searchTerm})

            }
          } else if (this.persistSearch) {
            this.$emit('filtered', {term: this.searchTerm, result: result})

            this.searchResponse = [];
          } else {
            this.searchResponse = result.response.data.data.map(service => {
              return {...service, ...service.attributes}
            })

          }
        });
      } else {
        this.clear()
      }
    }
  }
}
</script>

<style scoped>

</style>
