<template>
  <form @submit.prevent="submit">

    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Package</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field label="Year">
          <b-select expanded required v-model="packageObject.year" placeholder="Year">
            <option
                v-for="year of $store.getters['dates/years']"
                :key="year + 'year'"
                :value="year"
            >{{ year }}
            </option>
          </b-select>
        </b-field>

        <b-field expanded label="Phases">

          <b-dropdown
              append-to-body
              expanded
              v-model="packageObject.phase_ids"
              multiple
              aria-role="list"
          >

            <b-button class="select" expanded slot="trigger"
                      :disabled="packageObject.phase_ids===null"
            >
              Grade
            </b-button>

            <b-dropdown-item
                v-for="phase in phases"
                :type="{'is-hidden': packageObject.phase_ids === null }"
                :key="phase.id"
                :value="phase.id"
                aria-role="listitem"
                required
            >
              <span>{{ phase.name }}</span>
            </b-dropdown-item>
          </b-dropdown>

        </b-field>

        <b-field label="Name">
          <b-input
              v-model="packageObject.name"
              placeholder="Package name"
              required>
          </b-input>
        </b-field>
        <b-field  label="Description" style="overflow: hidden">
          <!--suppress HtmlUnknownTag -->
          <ckeditor v-if="loaded" style="overflow: hidden" :editor="editor" v-model="packageObject.description"
                    :config="editorConfig"></ckeditor>
        </b-field>
        <b-field label="Is Open For Signups">
          <b-switch :true-value="1" :false-value="0" v-model="packageObject.is_open">
            {{ packageObject.is_open === 1 ? 'Yes' : 'No' }}
          </b-switch>
        </b-field>
        <b-field :label="'Services'">
          <PerfectScrollbarWrapper>
          <b-table
              class="margin-top is-fullwidth"
              :data="packageObject.services"
              :striped="true"
              :hoverable="true"
              :bordered="true"
          >
            <template #empty>
              <div class="has-text-centered">No services</div>
            </template>
            <b-table-column
                v-slot="props"
                label="ID"
                field="id"
                sortable
                width="40"
                numeric
            >{{ props.row.id }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Name"
                field="name"
                sortable

            >{{ props.row.name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Cost"
                field="cost"
                sortable
                numeric
            >{{ props.row.cost }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Adhoc Cost"
                field="adhoc_cost"
                sortable
                numeric
            >{{ props.row.adhoc_cost }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Billing Period"
                field="billing_period"
                sortable
                numeric
            >{{ props.row.billing_period }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Notice Period"
                field="notice_period"
                sortable
                numeric
            >{{ props.row.notice_period }}
            </b-table-column>
            <!--suppress EqualityComparisonWithCoercionJS -->
            <b-table-column
                v-slot="props"
                label="Has Attendances"
                field="has_attendances"
                sortable
                numeric
            >{{ props.row.has_attendances == true ? 'Yes' : 'No' }}
            </b-table-column>

            <!--suppress EqualityComparisonWithCoercionJS -->
            <b-table-column
                v-slot="props"
                label="Has Adhoc Attendants"
                field="has_ad_hoc_attendants"
                sortable
                numeric
            >{{ props.row.has_ad_hoc_attendants == true ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Is Open"
                field="is_open"
                sortable
                numeric
            >{{ props.row.is_open ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column v-slot="props" narrowed centered custom-key="actions">
              <b-field grouped>
                <b-field>
                  <b-tooltip label="Remove" size="is-small" type="is-danger">
                    <b-button
                        type="is-danger"
                        :icon-right="$tc('icons.minus')"
                        @click="removeService(props.row)"
                    ></b-button>
                  </b-tooltip>
                </b-field>
              </b-field>
            </b-table-column>
          </b-table>
          </PerfectScrollbarWrapper>
        </b-field>
        <b-field :label="`Add Service`">
          <ServicesFilter :dropdown-direction="'top'" :clear-on-select="true" :with_permission="'create services'"
                          :type="'autocomplete'" @selected="selectService"
                          :year="packageObject.year"
                          :campus_id="packageObject.campus_id"></ServicesFilter>
        </b-field>
      </section>
      <footer :class="{'modal-card-foot':inModal}">
        <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";
import ServicesFilter from "@/components/services/ServicesFilter";
import Package from "@/models/Package";
import Phase from "@/models/Phase";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";


export default {
  name: "PackageForm",
  components: {
    PerfectScrollbarWrapper,
    ServicesFilter
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "The package description"},
      },
      loaded: false,
      packageObject: {
        name: '',
        campus_id: this.campus_id,
        phase_ids: [],
        services: [],
        service_ids: [],
        is_open: 0,
        description: null,
        year: this.$store.state.dates.year
      }
    }
  },
  computed: {
    phases() {
      return Phase.query().where('year', this.packageObject.year).where('is_open_for_enrolment',1).where('campus_id', this.packageObject.campus_id).get()
    }
  },
  methods: {

    removeService(item) {
      this.packageObject.services = this.packageObject.services.filter(service => service.id !== item.id)
      this.packageObject.service_ids = this.packageObject.service_ids.filter(service_id => service_id !== item.id)
    },
    selectService(service) {
      this.packageObject.services.push(service)
      this.packageObject.service_ids.push(service.id)
    },
    submit() {

      if (this.edit) {
        Package.Update({
          id: this.packageObject.id,
          name: this.packageObject.name,
          campus_id: this.campus_id,
          services: this.packageObject.service_ids,
          is_open: this.packageObject.is_open,
          phases: this.packageObject.phase_ids,
          description: this.packageObject.description,
          year: this.packageObject.year
        }).then((result) => {
          Package.FetchById({id: result.entities.packages[0].id}, ['services', 'phases']).then(() => {
            this.$buefy.snackbar.open(`Package updated!`)
            this.$emit('close')
          })

        }).catch(err => {
          this.handleError(err)
        })
      } else {
        Package.Store({
          name: this.packageObject.name,
          campus_id: this.campus_id,
          services: this.packageObject.service_ids,
          is_open: this.packageObject.is_open,
          phases: this.packageObject.phase_ids,
          description: this.packageObject.description,
          year: this.packageObject.year
        }).then((result) => {
          Package.FetchById({id: result.entities.packages[0].id}, ['services', 'phases']).then(() => {
            this.$buefy.snackbar.open(`Package created!`)
            this.$emit('close')
          })
        }).catch(err => {
          this.handleError(err)
        })
      }


    }
  }
  ,
  mounted() {
    // Package.FetchAll({page: 1, limit: 999}, {campus_id: this.campus_id})
    if (this.edit) {
      this.packageObject = JSON.parse(JSON.stringify(this.proppedPackage))
      this.loaded = true
    }
    this.loaded = true

  }
  ,
  props: {
    campus_id: {
      type: Number,
      default
          () {
        return 0
      }
    },
    proppedPackage:
        {
          type: Object,
          default
              () {
            return {
              name: ''
            }
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

