<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Sports House</p>
      </header>
      <section :class="{'modal-card-body':inModal}">

        <b-field label="Name">
          <b-input
              expanded
              v-model="sportsHouseObject.name"
              placeholder="House name"
              required>
          </b-input>
        </b-field>
      </section>
      <footer :class="{'modal-card-foot':inModal}">
        <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>

import SportsHouse from "@/models/SportsHouse";

export default {
  name: "SportsHouseForm",
  data() {
    return {
      sportsHouseObject: {
        name: '',
        campus_id: this.campus_id,
       }
    }
  },
  methods: {
     submit() {
      if (this.edit) {
        SportsHouse.Update({
          id: this.sportsHouseObject.id,
          name: this.sportsHouseObject.name,
          campus_id: this.campus_id,
        },true).then(() => {
          this.$buefy.snackbar.open(`Sports House updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        SportsHouse.Store({
          name: this.sportsHouseObject.name,
          campus_id: this.campus_id,
        }).then(() => {
          this.$buefy.snackbar.open(`Sports House created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.sportsHouseObject = JSON.parse(JSON.stringify(this.sportsHouse))
    }
  }
  ,
  props: {
    campus_id: {
      type: Number,
      default
          () {
        return 0
      }
    },
    sportsHouse:
        {
          type: Object,
          default
              () {
            return {
              name: ''
            }
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

