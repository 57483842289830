<template>
  <div>


    <div ref="subjectsContainer">
      <b-tabs expanded multiline  v-model="subjectTab" class="hidden-tabs no-padding">
        <b-tab-item>
          <div v-for="programme in programmes" :key="programme.id">
            <p class="has-text-weight-semibold is-size-3">{{programme.name}}</p>
            <b-table class="is-clickable"
                     :striped="true"
                     :hoverable="true"
                     :bordered="false"
                     :data="programmeSubjects(programme.id)"
                     @click="editSubject">
              <template #empty>
                <div class="has-text-centered">No Subjects</div>
              </template>
              <b-table-column
                  v-slot="props"
                  label="ID"
                  field="id"
                  sortable
                  width="40"
                  numeric
              >{{ props.row.id }}
              </b-table-column>

              <b-table-column
                  v-slot="props"
                  sortable
                  field="name"
                  label="Name"
              >{{ props.row.name }}
              </b-table-column>
              <!-- <b-table-column
                  v-slot="props"
                  narrowed
                  centered
                  width="60"
                  label="Actions"
                  cell-class="py-1"
                  custom-key="actions"
              >
                <b-field grouped>
                  <b-field>
                    <b-button @click="editSubject(props.row)" type="is-primary"
                    >View
                    </b-button
                    >
                  </b-field>
                </b-field>
              </b-table-column> -->
            </b-table>
          </div>
<!--          <b-pagination-->
<!--              class="mt-4"-->
<!--              :total="meta.total"-->
<!--              :current="page"-->
<!--              :range-before="2"-->
<!--              :range-after="2"-->
<!--              :per-page="limit"-->
<!--              :loading="loadingData"-->
<!--              aria-next-label="Next page"-->
<!--              aria-previous-label="Previous page"-->
<!--              aria-page-label="Page"-->
<!--              aria-current-label="Current page"-->
<!--              v-on:change="setPage"-->
<!--          ></b-pagination>-->
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import SubjectForm from "@/components/panelled-dash/SubjectForm";
import Subject from "@/models/Subject";
import Programme from "@/models/Programme";

export default {
  name: 'ProgrammesPanel',
  components: {},
  data() {
    return {
      subjectTab: 0,
      loadingData: false,
      page: 1,
      limit: 20,
      subjectsDataHold: null,
      meta: {},
      subjectFilter: null
    }
  },
  mounted() {
    // this.setPage(1)
    this.$store.dispatch('loader/show')
    Programme.FetchAll({page:1,limit:99},['subjects']).then(()=>{
      this.$store.dispatch('loader/hide')
    })
  },
  watch: {
    // filters() {
    //   this.setPage(1)
    // }
  },
  computed: {
    programmes(){
      return Programme.query().orderBy('name').with('subjects').get()
    },
    filters() {
      return {

        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}),
        year: this.$store.state.dates.year,
        is_for_programmes: 1,
        ...(this.subjectFilter !== null ? {search: this.subjectFilter} : {})
      };
    },

    subjects() {
      return Subject.query().where('is_for_programmes', 1).where('campus_id', this.$store.state.campus.selected_campus_id).get()
    }

  },
  methods: {
    getFiltered(text) {
      this.subjectFilter = text
    },
    programmeSubjects(programme_id){
      return Programme.query().where('id',programme_id).with('subjects').first()?.subjects
    },
    setPage(pageNumber) {
      this.$store.dispatch('loader/show')
      this.subjectsDataHold = this.subjects;
      Subject.deleteAll();
      this.page = pageNumber;
      Subject.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters,
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingFilter = false
            this.$store.dispatch('loader/hide')
            this.subjectsDataHold = null;
          }
      );
    },

    createClick() {
      if (this.subjectTab === 0) {
        if (this.canCreateSubject) {
          this.startCreateSubject()
        } else {
          this.$store.dispatch('toast/createToast')
        }
      } else {
        if (this.canCreateExtramural) {
          this.startCreateExtramural()
        } else {
          this.$store.dispatch('toast/createToast')
        }
      }
    },


    editSubject(subject) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          canEditFields: false,
          subject: subject
        },
        component: SubjectForm,
        hasModalCard: true,
        trapFocus: true
      })
    },
    deleteSubject(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting subject',
        confirmText: 'Delete Subject',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this subject?',
        onConfirm: () => Subject.Delete(id).then(() => {
          this.$buefy.snackbar.open(`Subject deleted!`)
        }).catch(err => {
          this.handleError(err)
        })
      })
    },
    startCreateSubject() {
      if (this.$store.state.campus.selected_stage) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            edit: false,
            stage_id: this.$store.state.campus.selected_stage.id,
            campus_id: this.activeCampusId
          },
          component: SubjectForm,
          hasModalCard: true,
          trapFocus: true
        })
      } else {
        this.$store.dispatch('toast/createToast', {message: 'Select a stage first'})
      }
    },
  },
  props: {
    canCreateSubject: {
      type: Boolean, default() {
        return false
      }
    },
    showAttendances: {
      type: Boolean, default() {
        return false
      }
    },
    canEditSubject: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteSubject: {
      type: Boolean, default() {
        return false
      }
    },

    activeCampusId: {},

  }
}
</script>
