<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Term</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field expanded label="Name">
          <b-input
              v-model="termObject.name"
              placeholder="Term name"
              expanded
              required>
          </b-input>
        </b-field>
        <b-field expanded label="Parent Term">
          <b-select expanded v-model="termObject.parent_id">
            <option :value="null">Top Level</option>
            <option v-for="term in terms" :key="term.id" :value="term.id">{{ term.name }}</option>
          </b-select>
        </b-field>
        <b-field expanded label="Start">
          <b-datepicker
              :years-range="[-80,80]" placeholder="Start" expanded
              v-model="start_date"></b-datepicker>
        </b-field>
        <b-field expanded label="End">
          <b-datepicker
              :years-range="[-80,80]" placeholder="End" expanded
              v-model="end_date"></b-datepicker>
        </b-field>


      </section>
      <footer :class="{'modal-card-foot':inModal}">
        <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>

import Term from "@/models/Term";
import {format, isValid} from "date-fns";

export default {
  name: "TermForm",
  data() {
    return {
      termObject: {
        name: '',
        start: null,
        end: null,
        campus_id: this.campus_id,
        parent_id: this.parent_id
      }
    }
  },
  computed: {
    start_date: {
      get() {
        if (isValid(new Date(this.termObject.start))) {
          return new Date(this.termObject.start)
        }
        return null
      }, set(newValue) {
        this.termObject.start = format(newValue, 'yyyy-MM-dd')
      },
    }, end_date: {
      get() {
        if (isValid(new Date(this.termObject.end))) {
          return new Date(this.termObject.end)
        }
        return null
      }, set(newValue) {
        this.termObject.end = format(newValue, 'yyyy-MM-dd')
      },
    },
  },
  methods: {


    submit() {
      if (this.edit) {
        Term.Update({
          id: this.termObject.id,
          name: this.termObject.name,
          start: this.termObject.start,
          end: this.termObject.end,
          campus_id: this.termObject.campus_id,
          parent_id: this.termObject.parent_id
        }).then(() => {
          this.$buefy.snackbar.open(`Term updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        Term.Store({
          id: this.termObject.id,
          name: this.termObject.name,
          start: this.termObject.start,
          end: this.termObject.end,
          campus_id: this.termObject.campus_id,
          parent_id: this.termObject.parent_id
        }).then(() => {
          this.$buefy.snackbar.open(`Term created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.termObject = this.term
    } else {
      this.start_date = new Date()
      this.end_date = new Date()
    }
  }
  ,
  props: {
    campus_id: {
      type: Number,
      default
          () {
        return 0
      }
    }, parent_id: {
      type: Number,
      default
          () {
        return null
      }
    },
    term:
        {
          type: Object,
          default
              () {
            return {
              name: ''
            }
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    terms:{
      type:Array,
      required:true
    },
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

