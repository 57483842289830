<template>
  <div>
    <div class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li v-if="$store.state.campus.selected_phase" class="is-size-3">{{
                  $store.state.campus.selected_phase.name
                }}
              </li>
              <li v-if="$store.state.campus.selected_stage" class="is-size-3">{{
                  $store.state.campus.selected_stage.name
                }}
              </li>
              <li class="is-size-3">{{ subjectTab === 1 ? 'Extramurals' : 'Subjects' }}</li>
            </ul>
          </nav>

        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <div v-if="canViewExtramural" class="level-item">
          <b-tooltip
              :label="`Switch to ${subjectTab===0?'extramurals':'subjects'}`"
              size="is-small"
              type="is-success"
              multilined
          >
            <div class="field">
              <transition mode="out-in" name="fade">
                <b-button :key="subjectTab" type="is-primary" @click="subjectTab = +!subjectTab">
                  {{ subjectTab === 0 ? 'Extramurals' : 'Subjects' }}
                </b-button>
              </transition>
            </div>
          </b-tooltip>
        </div>
        <div v-if="canCreateExtramural || canCreateSubject"
             class="level-item mr-4">

          <b-tooltip
              :label="`Create a new ${subjectTab===0?'subject':'extramural'} in this stage`"
              size="is-small"
              type="is-success"
              multilined
          >
            <a
                class="card-header-icon has-text-primary px-0 py-0"
                @click.prevent.stop="createClick()"
            >
              <b-icon :icon="$tc('icons.create')" size="is-medium"/>
            </a>

          </b-tooltip>
        </div>
      </div>
    </div>
    <b-tabs expanded multiline  v-model="subjectTab" class="hidden-tabs no-padding">
      <b-tab-item>
        <SubjectsFilter v-if="$store.state.campus.selected_stage"
                        :type="'filter'"
                        @filtered="({term})=>this.$store.dispatch('campus/setFilterSubjectTerm', term)"
                        @cleared="$store.dispatch('campus/setFilterSubjectTerm',null)"
                        :campus_id="activeCampusId"
                        :mode="'create'"
                        :stage_id="$store.state.campus.selected_stage.id"></SubjectsFilter>
      </b-tab-item>
      <b-tab-item v-if="canViewExtramural">
        <ExtramuralsFilter v-if="$store.state.campus.selected_stage"
                           :type="'filter'"
                           @filtered="({term})=>this.$store.dispatch('campus/setFilterExtramuralTerm', term)"
                           @cleared="$store.dispatch('campus/setFilterExtramuralTerm',null)"
                           :campus_id="activeCampusId"
                           :stage_id="$store.state.campus.selected_stage.id"></ExtramuralsFilter>
      </b-tab-item>
    </b-tabs>
    <hr>
    <div class="subjectsContainer" @mouseenter="psUpdate" ref="subjectsContainer">
      <b-tabs expanded multiline  v-model="subjectTab" class="hidden-tabs no-padding">
        <b-tab-item>
          <SubjectsTable v-if="$store.state.campus.selected_stage!==null" @start-delete-subject="deleteSubject"
                         @start-edit-subject="editSubject"
                         @page-change="alert('s')"
                         :show-attendances="showAttendances" :subjects="selectedStageSubjects"
                         :canEditSubject="canEditSubject" :canDeleteSubject="canDeleteSubject"
          ></SubjectsTable>
        </b-tab-item>
        <b-tab-item v-if="canViewExtramural">
          <ExtramuralsTable v-if="$store.state.campus.selected_stage!==null" @start-delete-extramural="deleteExtramural"
                            @start-edit-extramural="editExtramural"
                            :show-attendances="showAttendances"
                            :extramurals="selectedStageExtramurals"
                            :canEditExtramural="canEditExtramural" :canDeleteExtramural="canDeleteExtramural"
          ></ExtramuralsTable>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import ExtramuralsFilter from "@/components/panelled-dash/ExtramuralsFilter"
import ExtramuralsTable from "@/components/panelled-dash/ExtramuralsTable"
import SubjectsFilter from "@/components/panelled-dash/SubjectsFilter"
import SubjectsTable from "@/components/panelled-dash/SubjectsTable"
import SubjectForm from "@/components/panelled-dash/SubjectForm";
import Subject from "@/models/Subject";
import ExtramuralForm from "@/components/panelled-dash/ExtramuralForm";
import Extramural from "@/models/Extramural";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: 'SubjectsPanel',
  components: {ExtramuralsFilter, ExtramuralsTable, SubjectsFilter, SubjectsTable},
  data() {
    return {
      subjectTab: 0,
      ps: null
    }
  },
  mounted() {
    this.ps = new PerfectScrollbar(this.$refs.subjectsContainer);

  },
  computed: {
    selectedStageSubjects() {
      return Subject.query().where('stage_id', this.$store.state.campus.selected_stage !== null ? this.$store.state.campus.selected_stage.id : null).where('campus_id', this.activeCampusId).get()
    }
    ,
    selectedStageExtramurals() {
      return Extramural.query().where('stage_id', this.$store.state.campus.selected_stage !== null ? this.$store.state.campus.selected_stage.id : null).where('campus_id', this.activeCampusId).where(extramural => {
        return this.$store.state.campus.filter_extramural_term ? extramural.name.toLowerCase().includes(this.$store.state.campus.filter_extramural_term.toLowerCase()) : true
      }).get()
    }
  },
  methods: {
    updateScroll(){
      console.log('s')
    },
    createClick() {
      if (this.subjectTab === 0) {
        if (this.canCreateSubject) {
          this.startCreateSubject()
        } else {
          this.$store.dispatch('toast/createToast')
        }
      } else {
        if (this.canCreateExtramural) {
          this.startCreateExtramural()
        } else {
          this.$store.dispatch('toast/createToast')
        }
      }
    },
    psUpdate() {
      if (this.ps) {
        this.ps.update();
      }
    },

    editSubject(subject) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          subject: subject
        },
        component: SubjectForm,
        hasModalCard: true,
        trapFocus: true
      })
    },
    deleteSubject(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting subject',
        confirmText: 'Delete Subject',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this subject?',
        onConfirm: () => Subject.Delete(id).then(() => {
          this.$buefy.snackbar.open(`Subject deleted!`)
        }).catch(err => {
          this.handleError(err)
        })
      })
    },
    startCreateSubject() {
      if (this.$store.state.campus.selected_stage) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            edit: false,
            stage_id: this.$store.state.campus.selected_stage.id,
            campus_id: this.activeCampusId
          },
          component: SubjectForm,
          hasModalCard: true,
          trapFocus: true
        })
      } else {
        this.$store.dispatch('toast/createToast', {message: 'Select a stage first'})
      }
    }, editExtramural(extramural) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          extramural: extramural
        },
        component: ExtramuralForm,
        hasModalCard: true,
        trapFocus: true
      })
    },
    deleteExtramural(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting extramural',
        confirmText: 'Delete Extramural',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this extramural?',
        onConfirm: () => Extramural.Delete(id).then(() => {
          this.$buefy.snackbar.open(`Extramural deleted!`)
        }).catch(err => {
          this.handleError(err)
        })
      })
    },
    startCreateExtramural() {
      if (this.$store.state.campus.selected_stage) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            edit: false,
            stage_id: this.$store.state.campus.selected_stage.id,
            campus_id: this.activeCampusId
          },
          component: ExtramuralForm,
          hasModalCard: true,
          trapFocus: true
        })
      } else {
        this.$store.dispatch('toast/createToast', {message: 'Select a stage first'})
      }
    },
  },
  props: {
    canCreateSubject: {
      type: Boolean, default() {
        return false
      }
    },
    showAttendances: {
      type: Boolean, default() {
        return false
      }
    },
    canEditSubject: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteSubject: {
      type: Boolean, default() {
        return false
      }
    },
    canCreateExtramural: {
      type: Boolean, default() {
        return false
      }
    }, canViewExtramural: {
      type: Boolean, default() {
        return false
      }
    },
    canEditExtramural: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteExtramural: {
      type: Boolean, default() {
        return false
      }
    },

    activeCampusId: {},

  }
}
</script>
