<template>
  <div>
    <div class="columns">
      <div class="column">

        <b-field grouped group-multiline>
          <b-field label="Focused Term" class="is-flex is-align-items-center" horizontal>
            <p>{{ focused_term ? focused_term.name : 'None' }}</p>
          </b-field>
          <p class="control is-expanded">
            <b-field expanded horizontal label="Year">
              <b-numberinput expanded v-model="year" min="2020" step="1">

              </b-numberinput>
            </b-field>
          </p>


          <p class="control is-expanded">
            <b-button expanded @click="clearFilters()" :icon-right="'cancel'" type="is-primary">
              Clear Filters
            </b-button>
          </p>
          <p v-if="canCreate" class="control is-expanded">
            <b-field expanded>
              <b-button
                  expanded
                  @click="startCreatingTerm(focused_term ? focused_term.id : null)"
                  type="is-primary"
                  :icon-right="$tc('icons.create')"
              >Create new Term
              </b-button>
            </b-field>
          </p>
        </b-field>


      </div>
    </div>
    <PerfectScrollbarWrapper>
    <b-table
        class="margin-top is-fullwidth is-clickable"
        :data="terms"
        :loading="loadingData"
        :striped="true"
        :hoverable="true"
        :bordered="false"
        :scrollable="false"
        @click="viewTerm"
    >
      <template #empty>
        <div class="has-text-centered">No Terms</div>
      </template>
      <b-table-column
          v-slot="props"
          label="ID"
          field="id"
          sortable
          width="40"
          numeric
      >{{ props.row.id }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Name"
          field="name"
          sortable

      >{{ props.row.name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Parent"
          field="parent_id"
          sortable

      >{{ props.row.parent_id ? (focused_term_hold ? focused_term_hold.name : props.row.parent.name) : "Top Level" }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Start"
          field="start"
          sortable

      >{{ props.row.start }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="End"
          field="end"
          sortable

      >{{ props.row.end }}
      </b-table-column>
      <b-table-column v-slot="props"
                      narrowed
                      centered
                      width="60"
                      label="Sub terms"
                      cell-class="py-1"
                      custom-key="actions">

        <b-field v-if="props.row.children.length>0">
          <b-button @click="focusTerm(props.row)" type="is-primary" v-on:click.stop>
            Sub terms
          </b-button>
        </b-field>
        <span v-else>No Sub terms</span>
      </b-table-column>
      <b-table-column
          v-slot="props"
          label=""
          custom-key="actions"
          centered

      >

        <div v-on:click.stop>
          <b-dropdown position="is-bottom-left" append-to-body aria-role="list" >
            <template #trigger>
              <b-icon
                  :icon="$tc('icons.more')"/>
            </template>
            <b-dropdown-item v-if="canCreate" @click="startCreatingTerm(props.row.id)" aria-role="listitem">
              Create Sub Term
            </b-dropdown-item>
            <b-dropdown-item v-if="canDelete" @click="startDelete(props.row)" aria-role="listitem">
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-table-column>

    </b-table>
    </PerfectScrollbarWrapper>
    <b-pagination
        class="mt-4"
        :total="meta.total"
        :current="page"
        :range-before="2"
        :range-after="2"
        :per-page="limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="setPage"

    ></b-pagination>
  </div>
</template>

<script>
import Term from "@/models/Term";

import TermForm from "@/components/terms/TermForm";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  components: { PerfectScrollbarWrapper },
  name: "Term.index",
  data() {
    return {
      search: null,
      loadingFilter: false,
      termsDataHold: null,
      focused_term: null,
      focused_term_hold: null,
      year: this.$store.state.dates.year,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      searchHold: null,
    };
  },
  props: {
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    }, canEdit: {
      type: Boolean,
      default: false,
    },

  },
  watch: {
    filters() {
      this.setPage(this.page);
    },
  },
  methods: {
    focusTerm(term) {
      this.focused_term = term
      this.focused_term_hold = term
    },
    clearFilters() {
      this.focused_term = null
      this.year = null
    },
    startDelete(term) {
      this.$buefy.dialog.confirm({
        title: `Deleting term`,
        confirmText: `Delete term`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this term?`,
        onConfirm: () =>
            Term.Delete(term.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Term deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreatingTerm(parent_id = null) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          parent_id: parent_id,
          inModal: true,
          edit: false,
          terms: this.terms,
          campus_id: this.$store.state.campus.selected_campus_id,
        },
        component: TermForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    viewTerm(term) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          term: term,
          terms: this.terms,
          campus_id: this.$store.state.campus.selected_campus_id,
        },
        component: TermForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },

    setPage() {
      this.loadingData = true;
      this.termsDataHold = this.terms;
      Term.deleteAll();
      Term.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters,
          ["children"]
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            if (this.focused_term === null) {
              this.focused_term_hold = null
            }
            this.loadingData = false;
            this.termsDataHold = null;
          }
      );
    },
  },

  computed: {

    filters() {
      return {
        ...(this.search
            ? {
              search: this.search,
            }
            : {}),
        ...(this.focused_term ? {
          parent_id: this.focused_term.id
        } : {}), ...(this.year ? {
          year: this.year
        } : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}),
      };
    },
    terms() {
      if (this.termsDataHold === null) {
        return Term.query().with("children").with('parent').orderBy('name').get();
      } else {
        return this.termsDataHold;
      }
    },

  },
  mounted() {
    this.$store.state.documentation.tab_name = 'terms'
    this.$store.state.documentation.active_doc_document_path = './Campus Administration/Manage Terms.md'
    this.setPage(this.page, true);


  },
};
</script>
