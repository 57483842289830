<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field grouped>

          <b-field v-if="canCreate">
            <b-button
                @click="startCreatingSportsHouse()"
                type="is-primary"
                :icon-right="$tc('icons.create')"
            >Create new Sports House
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <b-table
        class="margin-top is-clickable"
        :data="sportsHouses"
        :loading="loadingData"
        :striped="true"
        :hoverable="true"
        :bordered="false"
        @click="viewSportsHouse"
    >
      <template #empty>
        <div class="has-text-centered">No houses</div>
      </template>
      <b-table-column
          v-slot="props"
          label="ID"
          field="id"
          sortable
          width="40"
          numeric
      >{{ props.row.id }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Name"
          field="name"
          sortable

      >{{ props.row.name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label=""
          custom-key="actions"
          centered

      >
      <div v-on:click.stop>
        <b-dropdown position="is-bottom-left" append-to-body aria-role="list" >
          <template #trigger>
            <b-icon
                :icon="$tc('icons.more')"/>
          </template>
          <b-dropdown-item @click="startDelete(props.row)"
                          v-if="canDelete"
                          aria-role="listitem">Delete
          </b-dropdown-item>
        </b-dropdown>
      </div>
      </b-table-column>

    </b-table>
    <b-pagination
        class="mt-4"
        :total="meta.total"
        :current="page"
        :range-before="2"
        :range-after="2"
        :per-page="limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="setPage"
    ></b-pagination>
  </div>
</template>

<script>
import SportsHouse from "@/models/SportsHouse";
import SportsHouseForm from "@/components/sportsHouses/SportsHouseForm";

export default {
  name: "SportsHouse.index",
  data() {
    return {
      search: null,
      loadingFilter: false,
      sportsHousesDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      searchHold: null,
    };
  },
  props: {
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },

  },
  methods: {
    startDelete(sportsHouse) {
      this.$buefy.dialog.confirm({
        title: `Deleting sports House`,
        confirmText: `Delete sports House`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this sports House?`,
        onConfirm: () =>
            SportsHouse.Delete(sportsHouse.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Sports House deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreatingSportsHouse() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          campus_id: this.$store.state.campus.selected_campus_id,
        },
        component: SportsHouseForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    viewSportsHouse(sportsHouse) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          sportsHouse: sportsHouse,
          campus_id: this.$store.state.campus.selected_campus_id,
        },
        component: SportsHouseForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    getFilteredServices(text) {
      this.search = text;
    },
    setPage(pageNumber) {
      this.loadingData = true;
      this.sportsHousesDataHold = this.sportsHouses;
      SportsHouse.deleteAll();
      this.page = pageNumber;
      SportsHouse.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },this.filters
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.sportsHousesDataHold = null;
          }
      );
    },
  },
  watch: {
    filters() {
      this.setPage(this.page);
    },
  },
  computed: {
    filters() {
      return {
        ...(this.search
            ? {
              search: this.search,
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {})
      };
    },
    sportsHouses() {
      if (this.sportsHousesDataHold === null) {
        return SportsHouse.query().orderBy('name').get();
      } else {
        return this.sportsHousesDataHold;
      }
    },
    edit_sportsHouses() {
      return this.$store.getters["entities/user-permissions/find"](
          "edit sports"
      );
    },
  },
  mounted() {
    this.$store.state.documentation.tab_name = 'sports-houses'
    this.$store.state.documentation.active_doc_document_path = './Campus Administration/Manage Sports Houses.md'
    this.setPage(this.page, true);


  },
};
</script>
